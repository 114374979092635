import type { PointerLikeEvent, PointerLikeEventCoordinatesObject } from '../../../types/browserEvents';
import type { CardinalDirection } from '../../../types/misc';
import convertCardinalDirectionToAxis from '../../../utils/convertCardinalDirectionToAxis';
import convertCardinalDirectionToSide from '../../../utils/convertCardinalDirectionToSide';
import { isMobile } from '../../../utils/environment';
import makeLogger from '../../../utils/makeLogger';
import cropBoundingClientRectToWindow from '../../utils/cropBoundingClientRectToWindow';
import doesRectContainOtherRect from '../../utils/doesRectContainOtherRect';
import getClosestAncestorWhichCanBeScrolledInCardinalDirection from '../../utils/getClosestAncestorWhichCanBeScrolledInCardinalDirection';
import getCoordinatesObjectFromPointerLikeEvent from '../../utils/getCoordinatesObjectFromPointerLikeEvent';
import getScrollDistanceRemainingInCardinalDirection from '../../utils/getScrollDistanceRemainingInCardinalDirection';
import requestAnimationFrame from '../../utils/requestAnimationFrame';
// eslint-disable-next-line import/no-cycle
import { getRendererOrThrow } from '../index';
import getScrollDirection from './getScrollDirection';
import shouldPointerPositionCauseScrollIfPossible from './shouldPointerPositionCauseScrollIfPossible';

/*
  NOTE: this directory is intended to be called from within the content frame, not outside
*/

const logger = makeLogger(__filename);

let selectionEmulationState: {
  isAutoScrolling: boolean;
  lastPointerMoveEvent: PointerLikeEvent | null;
  originCoordinates: PointerLikeEventCoordinatesObject | null;
  originTarget: Node | null;
  scrollCardinalDirection: CardinalDirection | null;
  status: 'active' | 'inactive';
} = {
  isAutoScrolling: false,
  lastPointerMoveEvent: null,
  originCoordinates: null,
  originTarget: null,
  scrollCardinalDirection: null,
  status: 'inactive',
};
const selectionEmulationContainerId = 'selection-emulation';

const cardinalDirectionToScrollSide: { [key: string]: 'left' | 'top' } = {
  east: 'left',
  north: 'top',
  south: 'top',
  west: 'left',
};

const distanceFromEdgeToStartScrollingAt = isMobile ? 50 : 30;

// Recursively called while the auto-scrolled is enabled
function autoScroll() {
  if (
    !selectionEmulationState.isAutoScrolling ||
    !selectionEmulationState.scrollCardinalDirection ||
    !selectionEmulationState.originTarget
  ) {
    return;
  }

  let closestAncestorToScroll = getClosestAncestorWhichCanBeScrolledInCardinalDirection(
    selectionEmulationState.originTarget,
    selectionEmulationState.scrollCardinalDirection,
  );
  if (!closestAncestorToScroll) {
    return;
  }

  let totalDistanceToScroll = calculateAutoScrollDistanceForFrame(closestAncestorToScroll);
  /*
    We need to go upwards through scrollable ancestors and scroll them until we have scrolled the total
    distance. This is how browsers behave
  */
  while (totalDistanceToScroll > 0 && closestAncestorToScroll) {
    const remainingScrollDistance = getScrollDistanceRemainingInCardinalDirection(
      closestAncestorToScroll,
      selectionEmulationState.scrollCardinalDirection,
    );
    const distanceToScrollAncestor = Math.min(remainingScrollDistance, totalDistanceToScroll);

    closestAncestorToScroll.scrollBy({
      behavior: 'instant',
      [cardinalDirectionToScrollSide[selectionEmulationState.scrollCardinalDirection]]:
        distanceToScrollAncestor *
        (['east', 'north'].includes(selectionEmulationState.scrollCardinalDirection) ? -1 : 1),
    });

    totalDistanceToScroll -= distanceToScrollAncestor;
    closestAncestorToScroll = getClosestAncestorWhichCanBeScrolledInCardinalDirection(
      closestAncestorToScroll,
      selectionEmulationState.scrollCardinalDirection,
    );
  }

  requestAnimationFrame(autoScroll);
}

// When this is called, we know it's valid to scroll. This just decides how far per frame / how fast
function calculateAutoScrollDistanceForFrame(ancestor: HTMLElement): number {
  if (
    !selectionEmulationState.isAutoScrolling ||
    !selectionEmulationState.scrollCardinalDirection ||
    !selectionEmulationState.lastPointerMoveEvent
  ) {
    throw new Error('Bad state');
  }

  const lastPointerMoveEventCoordinates = getCoordinatesObjectFromPointerLikeEvent(
    selectionEmulationState.lastPointerMoveEvent,
  );
  const axis = convertCardinalDirectionToAxis(selectionEmulationState.scrollCardinalDirection);
  const cursorCoordinate = lastPointerMoveEventCoordinates[`client${axis}`];

  // This makes the calculations easier. Otherwise there would be a LOT of `if` statements
  const doCoordinatesGetSmallerInScrollCardinalDirection = ['east', 'north'].includes(
    selectionEmulationState.scrollCardinalDirection,
  );

  const ancestorRect = cropBoundingClientRectToWindow(ancestor.getBoundingClientRect());
  const ancestorEndCoordinate =
    ancestorRect[convertCardinalDirectionToSide(selectionEmulationState.scrollCardinalDirection)];
  const scrollZoneStartCoordinate =
    ancestorEndCoordinate +
    distanceFromEdgeToStartScrollingAt * (doCoordinatesGetSmallerInScrollCardinalDirection ? 1 : -1);

  let distancePastScrollZoneStart: number;
  if (doCoordinatesGetSmallerInScrollCardinalDirection) {
    distancePastScrollZoneStart = scrollZoneStartCoordinate - cursorCoordinate;
  } else {
    distancePastScrollZoneStart = cursorCoordinate - scrollZoneStartCoordinate;
  }
  distancePastScrollZoneStart = Math.min(
    distancePastScrollZoneStart,
    distanceFromEdgeToStartScrollingAt,
  );

  const maxAdditionalDistance = isMobile ? 10 : 20;
  return Math.round(
    5 + maxAdditionalDistance * (distancePastScrollZoneStart / distanceFromEdgeToStartScrollingAt),
  );
}

function startAutoScrolling(event: PointerLikeEvent, scrollCardinalDirection: CardinalDirection) {
  if (selectionEmulationState.status === 'inactive' || selectionEmulationState.isAutoScrolling) {
    return;
  }

  selectionEmulationState.isAutoScrolling = true;
  selectionEmulationState.lastPointerMoveEvent = event;
  selectionEmulationState.scrollCardinalDirection = scrollCardinalDirection;

  autoScroll();
}

function stopAutoScrolling() {
  if (selectionEmulationState.status === 'inactive' || !selectionEmulationState.isAutoScrolling) {
    return;
  }

  selectionEmulationState.isAutoScrolling = false;
  selectionEmulationState.lastPointerMoveEvent = null;
  selectionEmulationState.scrollCardinalDirection = null;
}

function onPointerMove(event: PointerEvent) {
  if (
    selectionEmulationState.status === 'inactive' ||
    !selectionEmulationState.originCoordinates ||
    !selectionEmulationState.originTarget
  ) {
    return;
  }
  selectionEmulationState.lastPointerMoveEvent = event;

  const scrollDirection = getScrollDirection(selectionEmulationState.originCoordinates, event);
  const scrollCardinalDirection = scrollDirection.split('-')[0] as CardinalDirection;

  const closestAncestorToScroll = getClosestAncestorWhichCanBeScrolledInCardinalDirection(
    selectionEmulationState.originTarget,
    scrollCardinalDirection,
  );

  if (
    shouldPointerPositionCauseScrollIfPossible({
      ancestor: closestAncestorToScroll,
      cardinalDirection: scrollCardinalDirection,
      distanceFromEdgeToStartScrollingAt,
      event,
    })
  ) {
    if (!selectionEmulationState.isAutoScrolling) {
      startAutoScrolling(event, scrollCardinalDirection);
    }
  } else if (selectionEmulationState.isAutoScrolling) {
    stopAutoScrolling();
  }
}

export function onSelectionChange() {
  if (selectionEmulationState.status !== 'active') {
    return;
  }

  document.getElementById(selectionEmulationContainerId)?.remove();

  const selection = getSelection();
  if (
    !selection?.rangeCount ||
    !selection
      .toString()
      .replace(/\u2060/g, '')
      .trim()
  ) {
    return;
  }

  const selContainer = document.createElement('div');
  selContainer.id = selectionEmulationContainerId;
  const range = selection.getRangeAt(0);
  if (!range) {
    return;
  }
  const containerNode = getRendererOrThrow().containerNode;
  if (!containerNode.contains(range.commonAncestorContainer)) {
    return;
  }

  const containerRect = containerNode.getBoundingClientRect();

  let selectionRects = Array.from(range.getClientRects());
  // Exclude rects which are inside other rects
  selectionRects = selectionRects.filter((mainRect, index) => {
    const otherRects = Array.from(selectionRects);
    otherRects.splice(index);
    return !otherRects.some((otherRect) => doesRectContainOtherRect(otherRect, mainRect));
  });

  for (const rect of selectionRects) {
    const selRect = document.createElement('span');
    for (const propertyName of ['top', 'right', 'bottom', 'left', 'height', 'width']) {
      let value = rect[propertyName];
      if (['top', 'left', 'right', 'bottom'].includes(propertyName)) {
        value = value - containerRect[propertyName];
      }
      selRect.style[propertyName] = `${value}px`;
    }
    selContainer.appendChild(selRect);
  }

  containerNode.appendChild(selContainer);
}

export function startEmulatingSelection(lastPointerDownEvent: PointerLikeEvent) {
  if (selectionEmulationState.status === 'active') {
    return;
  }

  if (!lastPointerDownEvent.target) {
    logger.warn('lastPointerDownEvent.target does not exist');
    return;
  }

  selectionEmulationState = {
    isAutoScrolling: false,
    lastPointerMoveEvent: null,
    originCoordinates: getCoordinatesObjectFromPointerLikeEvent(lastPointerDownEvent),
    originTarget: lastPointerDownEvent.target as Node,
    scrollCardinalDirection: null,
    status: 'active',
  };
  const containerNode = getRendererOrThrow().containerNode;
  containerNode.classList.add('is-emulating-selection');
  onSelectionChange(); // Draw current selection
  document.addEventListener('selectionchange', onSelectionChange);
  document.addEventListener('pointermove', onPointerMove);
}

export function stopEmulatingSelection() {
  if (selectionEmulationState.status === 'inactive') {
    return;
  }
  stopAutoScrolling();
  selectionEmulationState = {
    isAutoScrolling: false,
    lastPointerMoveEvent: null,
    originCoordinates: null,
    originTarget: null,
    scrollCardinalDirection: null,
    status: 'inactive',
  };
  document.removeEventListener('selectionchange', onSelectionChange);
  document.removeEventListener('pointermove', onPointerMove);
  getRendererOrThrow().containerNode.classList.remove('is-emulating-selection');
  document.getElementById(selectionEmulationContainerId)?.remove();
}
