import { documentSearchEngine } from './documentSearchEngine';
import background from './portalGates/toBackground/singleProcess';

export async function clearAllLocalData(shouldReloadApplication = true) {
  await background.clearAllLocalData(shouldReloadApplication);
}

export async function clearSearchAndContent() {
  await background.clearDocumentContentStore();
  await documentSearchEngine.destroy();
}
