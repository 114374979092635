import { MOBILE_CSS_FONTS, MOBILE_DEFAULT_FONT } from '../../../../../shared/foreground/fonts';
import type { Font } from '../../../../../shared/types';
import { Theme } from '../../../css/baseStyles';
import { generateAssetFontCss } from '../../../utils/getAssetFontCss';
import getWebviewHorizontalMarginCss from '../../../utils/getWebviewHorizontalMarginCss';
import { getBaseStyles } from './baseStyles';
import { getDigestStyles, getPDFPreviewStyles } from './digestStyles';
import { getEpubStyles } from './epubStyles';
import { getHeaderStyles } from './headerStyles';
import { getOriginalEmailStyles } from './originalEmailStyles';
import { getPaginationStyles } from './paginationStyles';
import { getTTSStyles } from './ttsStyles';

export type WebviewStyleProps = {
  font: Font;
  fontSize: number;
  horizontalMargin: number;
  isHighContrast: boolean;
  isRtl: boolean;
  areEpubOriginalStylesEnabled: boolean;
  shouldJustifyText: boolean;
  lineHeight: number;
  marginTop: number;
  deviceHeight: number;
  isDigestView: boolean;
  isEmailOriginalView: boolean;
  isPaginatedMode: boolean;
  isPDFDigestPreview: boolean;
  isYoutubeVideo: boolean;
  theme: Theme;
  isTablet: boolean;
  platformOS: 'ios' | 'android' | 'windows' | 'macos' | 'web';
};

export const getWebviewInnerStyle = ({
  deviceHeight,
  isDigestView,
  isEmailOriginalView,
  isPaginatedMode,
  isPDFDigestPreview,
  isYoutubeVideo,
  font,
  areEpubOriginalStylesEnabled,
  marginTop,
  isHighContrast,
  horizontalMargin,
  fontSize,
  lineHeight,
  shouldJustifyText,
  theme,
  platformOS,
  isTablet,
}: WebviewStyleProps) => {
  const { styleContent: fontFamilyStyles, links: fontLinks } = generateAssetFontCss({
    fontName: font,
    cssFontName: font ? MOBILE_CSS_FONTS[font] : MOBILE_CSS_FONTS[MOBILE_DEFAULT_FONT],
    platformOS,
  });

  return `
  ${areEpubOriginalStylesEnabled ? getEpubStyles(shouldJustifyText) : ''}
  <style id="RWMarginStyle">
    ${getWebviewHorizontalMarginCss(horizontalMargin, isTablet)}
  </style>
  <style id="RWFontFamilyStyle" font-name="${font}">
    ${fontFamilyStyles}
  </style>
  ${fontLinks.join(' ')}
  <style>
    :root {
      --reading-editable-font-size: ${fontSize};
      --reading-editable-line-height: ${lineHeight};
      --reading-editable-text-justify: ${shouldJustifyText ? 'justify' : 'start'};
    }
    body {
    }

    #generatedStyles {
      display: none;
    }

    .document-root {
      transition: ${isHighContrast ? '' : 'opacity 0.6s ease'};
      opacity: 1;
    }

    .centeredElementDebug {
      color: red!important;
    }

    .transparent {
      opacity: 0!important;
    }

    .display-none {
      display: none!important;
    }


    #readerCanvas {
      display: none;
    }

    .image-compare-canvas {
      display: none;
    }

    .debug-border {
      position: absolute;
      background: blue;
      display: none;
      height: 3px;
      width: 100%;
      z-index: 2000;
    }
  </style>
  ${getHeaderStyles({ isYoutubeVideo, theme, marginTop })}
  ${getTTSStyles({ theme, isHighContrast })}
  ${getBaseStyles({
    theme,
    marginTop,
  })}
  ${isPaginatedMode ? getPaginationStyles({ theme, deviceHeight }) : ''}
  ${
    platformOS !== 'ios'
      ? `<style>
      /* hack to prevent hyphens from breaking android text selection */
      .document-content {
        hyphens: initial!important;
      }
    </style>
  `
      : `<style></style>`
  }
  ${isDigestView ? getDigestStyles(horizontalMargin, isTablet) : ''}
  ${isPDFDigestPreview ? getPDFPreviewStyles(theme) : ''}
  ${isEmailOriginalView ? getOriginalEmailStyles() : ''}
`;
};
