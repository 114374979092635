import { populateFocusableElements } from '../../../shared/foreground/utils/findAllFocusableElements';
import { findCenteredElementInViewport } from '../../../shared/foreground/utils/findCenteredElementInViewport';
import { LenientReadingPosition } from '../../../shared/types';
import makeLogger from '../../../shared/utils/makeLogger';
import { ScrollingManagerError } from './errors';
import { animateEndOfReadingButton } from './initEndOfReading';
// eslint-disable-next-line import/no-cycle
import { ScrollingManager } from './ScrollingManager';

const logger = makeLogger(__filename, { shouldLog: false });
export class VerticalScrollingManager extends ScrollingManager {
  updateCenteredElementThrottleCounter = 0;
  currentlyScrollingBecauseOfTouch = false;
  currentHeight = 0;
  // This class handles specific vertical scrolling functions
  async init(firstTimeOpenedDocumentOffset: number): Promise<void> {
    if (this.initialized) {
      return;
    }
    await super.init(firstTimeOpenedDocumentOffset);
    this.window.addEventListener('scroll', this.onScroll.bind(this));
    this.window.addEventListener('touchmove', this.onTouchMove.bind(this));
    const elements = this.document.querySelectorAll<HTMLElement>('#document-text-content *');
    elements.forEach((element) => {
      element.addEventListener('scroll', () => {
        this.window.portalGateToForeground.emit('horizontal_scroll');
      });
    });

    this.updateCurrentCenteredElement();
    this.initialized = true;
    this.currentHeight = this.documentTextContent?.getBoundingClientRect().height ?? 0;
  }

  async onResize() {
    if (!this.initialized) {
      await this.init(0);
      this.initializeCallback();
      return;
    }
    const newHeight = this.documentTextContent?.getBoundingClientRect().height;
    if (this.documentTextContentHeight === newHeight || !newHeight) {
      return;
    }
    this.documentTextContentHeight = newHeight;
    logger.debug(`OnResize: fired , ${this.getScrollingElementTop()}`);
    if (this.getScrollingElementTop() <= this.firstTimeOpenedDocumentOffset) {
      // If we are at the top, we don't need to do anything
      return;
    }
    const oldScrollTop = this.currentCenteredElementInfo?.scrollDelta;
    if (oldScrollTop === undefined || !this.currentCenteredElementInfo.element) {
      logger.debug('OnResize failed due to no center element');
      return;
    }
    if (this.currentlyScrollingBecauseOfTouch) {
      logger.debug(
        `resize blocked, currentlyScrollingBecauseOfTouch: ${this.currentlyScrollingBecauseOfTouch};`,
      );
      return;
    }

    const element = this.currentCenteredElementInfo.element;
    const y = element.getBoundingClientRect().top + this.window.scrollY - oldScrollTop;
    this.setScrollingElementTop(Math.max(0, y));
  }

  updateCurrentCenteredElement() {
    logger.debug('UpdateCurrentCenteredElement fired');
    if (this.updatingCenterElementDisabled) {
      return;
    }
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('UpdateCurrentCenteredElement Document Text Container not found');
    }
    if (!this.highlightableElements.length) {
      populateFocusableElements(this.documentTextContent, this.highlightableElements);
    }
    const centeredElement = findCenteredElementInViewport(
      this.highlightableElements,
      this.window,
    ) as HTMLElement;
    logger.debug('updateCurrentCenteredElement ', {
      centeredElement,
      top: centeredElement?.getBoundingClientRect().top,
    });

    // Uncomment for debug purposes
    // const prevElementDebug = this.document.querySelector('.centeredElementDebug');
    // prevElementDebug?.classList.remove('centeredElementDebug');
    // centeredElement?.classList.add('centeredElementDebug');
    // console.log("remember to comment me back out")

    this.currentCenteredElementInfo = {
      element: centeredElement,
      scrollDelta: centeredElement?.getBoundingClientRect().top,
    };
  }

  scrollToReadingPosition(readingPosition: LenientReadingPosition) {
    if (readingPosition.serializedPosition) {
      try {
        this.scrollToSerializedPosition(
          readingPosition.serializedPosition,
          readingPosition.mobileSerializedPositionElementVerticalOffset ?? 0,
        );
      } catch (e) {
        if (readingPosition.scrollDepth) {
          this.scrollToPercentOfViewport(readingPosition.scrollDepth);
        }
      }
    } else if (readingPosition.scrollDepth) {
      this.scrollToPercentOfViewport(readingPosition.scrollDepth);
    }
    this.updateCurrentCenteredElement();
  }

  isDocumentScrolledToBeginning(): boolean {
    const scrollableRoot = this.getScrollingElement();
    return scrollableRoot.scrollTop < 100;
  }

  // -150 because we pretty much NEVER want the element to be right at the very top of the screen...
  scrollToElement(element: Element, offset = -150, behavior: 'smooth' | 'auto' | 'instant' = 'instant') {
    const y = element.getBoundingClientRect().top + this.window.scrollY + offset;
    this.scrollingElementScrollTo({ top: y, behavior });
  }

  scrollToRect(rect: DOMRect, offset = 0) {
    const newTop = Math.floor(this.getScrollingElementTop() + rect.top) + offset;
    this.scrollingElementScrollTo({ top: newTop, behavior: 'smooth' });
  }

  scrollToTop() {
    if (this.currentlyScrollingBecauseOfTouch) {
      return;
    }
    this.disableScrollEventsForNMilliseconds();
    super.scrollToTop();
  }

  scrollToPosition(scrollTop: number) {
    const scrollingElement = this.getScrollingElement();
    scrollingElement.scrollTo({ top: scrollTop });
  }

  scrollToPercentOfViewport(percent: number, animated = false, disableEvents = false) {
    if (disableEvents) {
      this.disableScrollEventsForNMilliseconds();
    }
    const { scrollHeight } = this.getScrollingElement();
    const newScrollTop = scrollHeight * percent;
    if (animated) {
      this.scrollingElementScrollTo({ top: newScrollTop, behavior: 'smooth' });
    } else {
      this.setScrollingElementTop(newScrollTop);
    }
  }

  scrollViewportToCurrentTTSLocation(rect: DOMRect) {
    const offsetFromTop = 350;
    const scrollByAmount = rect.top - offsetFromTop;
    const scrollableRoot = this.getScrollingElement();
    const newScrollTop =
      Math.abs(scrollByAmount) > 100
        ? scrollableRoot.scrollTop + scrollByAmount
        : scrollableRoot.scrollTop;
    if (this.ttsAutoScrollingEnabled) {
      scrollableRoot.scrollTo({ top: newScrollTop, behavior: 'smooth' });
    }
  }

  returnToReadingPosition() {
    this.disableScrollEventsForNMilliseconds(400);
    if (this.readingPosition) {
      this.scrollToReadingPosition(this.readingPosition);
    }
    setTimeout(() => {
      const { scrollTop, scrollHeight, clientHeight } = this.getScrollingElement();
      const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();
      this.window.portalGateToForeground.emit('return_to_reading_position', {
        currentScrollValue: scrollTop,
        maxScrollValue: scrollHeight,
        clientScrollableWindowSize: clientHeight,
        serializedPosition: serializedPositionInfo?.serializedPosition,
        serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
      });
    }, 400);
  }

  onScrollStart() {
    const { scrollTop, scrollHeight, clientHeight } = this.getScrollingElement();
    this.updateCenteredElementThrottleCounter = 0;
    if (!this.scrollingEventsDisabled) {
      const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();
      this.window.portalGateToForeground.emit('scroll_start', {
        currentScrollValue: scrollTop,
        maxScrollValue: scrollHeight,
        clientScrollableWindowSize: clientHeight,
        serializedPosition: serializedPositionInfo?.serializedPosition,
        serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
      });
    }
  }

  onScroll() {
    if (!this.headerImageContainer) {
      throw new ScrollingManagerError('Header image element not found');
    }
    const { scrollTop, scrollHeight, clientHeight } = this.getScrollingElement();
    this.currentScrollValue = scrollTop;

    const newIsScrollingDown = this.previousScrollValue < this.currentScrollValue;
    if (newIsScrollingDown !== this.isScrollingDown || !this.scrollTimer) {
      this.onScrollStart();
    }

    if (this.scrollTimer) {
      clearTimeout(this.scrollTimer);
    }

    this.isScrollingDown = newIsScrollingDown;
    this.previousScrollValue = this.currentScrollValue;

    if (!this.scrollingEventsDisabled) {
      const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();
      this.window.portalGateToForeground.emit('scroll', {
        currentScrollValue: scrollTop,
        maxScrollValue: scrollHeight,
        clientScrollableWindowSize: clientHeight,
        serializedPosition: serializedPositionInfo?.serializedPosition,
        serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
      });
    }
    if (scrollTop < -5 || this.headerImageContainer.style.transform !== 'scale(1)') {
      const val = Math.min(3, 1 + -scrollTop * 0.005);
      this.headerImageContainer.style.transform = `scale(${Math.max(1, val)})`;
    }
    const scrollFromBottom = scrollHeight - scrollTop - clientHeight;

    // This check prevents animations before we have fully loaded the DOM
    if (!this.scrollingEventsDisabled) {
      animateEndOfReadingButton(scrollFromBottom);
    }

    this.scrollTimer = setTimeout(this.onScrollEnd.bind(this), 100);
    for (const func of this.scrollListeners) {
      func();
    }
    if (this.updateCenteredElementThrottleCounter === 2) {
      this.updateCurrentCenteredElement();
    }
    if (this.updateCenteredElementThrottleCounter > 2) {
      this.updateCenteredElementThrottleCounter = 0;
    }
    this.updateCenteredElementThrottleCounter += 1;
  }

  onScrollEnd() {
    logger.debug('OnScrollEnd fired');
    this.currentlyScrollingBecauseOfTouch = false;
    if (this.scrollTimer) {
      clearTimeout(this.scrollTimer);
    }
    this.updateCenteredElementThrottleCounter = 0;
    this.updateCurrentCenteredElement();
    const { scrollTop, scrollHeight, clientHeight } = this.getScrollingElement();
    if (!this.scrollingEventsDisabled) {
      const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();
      this.window.portalGateToForeground.emit('scroll_end', {
        currentScrollValue: scrollTop,
        maxScrollValue: scrollHeight,
        clientScrollableWindowSize: clientHeight,
        serializedPosition: serializedPositionInfo?.serializedPosition,
        serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
      });
    }
    this.scrollTimer = undefined;
  }

  onTouchMove() {
    this.currentlyScrollingBecauseOfTouch = true;
    if (this.touchMoveThrottle === 0) {
      this.window.portalGateToForeground.emit('touch_move');
    }
    this.touchMoveThrottle += 1;
    if (this.touchMoveThrottle > 10) {
      this.touchMoveThrottle = 0;
    }
  }
}
