import type { CardinalDirection, Side } from '../types/misc';

const cardinalDirectionToSide: { [key: string]: Side } = {
  east: 'left',
  north: 'top',
  south: 'bottom',
  west: 'right',
};

export default function convertCardinalDirectionToSide(cardinalDirection: CardinalDirection): Side {
  return cardinalDirectionToSide[cardinalDirection];
}
